import React, { useEffect, useState } from 'react'
import './Steps.scss'
import Input from '../../../../Components/Input/Input'
import Select from '../../../../Components/Select/Select'
import InputWithRemove from '../Components/Input/InputWithRemove'
import {ReactComponent as AddIcon} from '../../../../assets/Vector (5).svg'
function ColorAndSize({body,normalizeBody,setBody}) {
  const [color,setColor] = useState(body?.color||[{id:Math.random(),value:''}])
  const changeColorValue = (id,value)=>{
    const changedArray = color.map(i=>{
      if(i.id === id){
        return {...i,value:value}
      }else{
        return i
      }
    })
      setColor(changedArray)
      return changedArray.filter(i=>i.value)
  }
  useEffect(()=>{
    const color = body
    if(!color?.color?.length){
      delete  color['color']
       setBody(color)
    }
  },[color])
  console.log(body)
  console.log(color)
  return (
    <div className='color-container'>
          <div className='color-detail'>
            <div className='color-detail-info'>
                <p>Color</p>
                <span>Write your product color, such as "red" or "navy blue," to assist shoppers in finding exactly what they're looking for</span>
            </div>
            <div className='color-info-container'>
            <p>Color</p>
          <div className='input-container'>
            {/* <InputWithRemove/> */}
             {color?.map((i)=><InputWithRemove removeAction={() => {
               const changedArray = color?.filter((item) => item?.id !== i.id)
               setColor(changedArray)
              normalizeBody('color',changedArray)
             }} showRemove={color.length>1} key={i?.id} value={i?.value} changeValue={(e)=>normalizeBody('color',changeColorValue(i?.id,e.target.value))}/>)}
            <div className='actions' onClick={()=>setColor([...color,{id:Math.random(),value:''}])}><AddIcon/><span>Add color</span></div>
            <div>

            </div>
          </div>

        </div>
        </div>
   
    </div>
  )
}

export default ColorAndSize