import React from 'react'
import './Steps.scss'
import Input from '../../../../Components/Input/Input'
import Select from '../../../../Components/Select/Select'
import CurencyInput from '../../../../Components/CurencyBar/CurencyInput'
function InventoryAndPriceing({body,normalizeBody}) {

  return (
    <div className='price-container'>
          <div className='price-detail'>
            <div className='price-detail-info'>
                <p>Quantity</p>
                <span>For quantities greater than one. this listing will renew automatically until is sells out.</span>
            </div>
            <div className='price-info-container'>
                <Input value={body?.Quantity} name={'Quantity'} req={true} styles={{border: "1px solid #0B7E9B"}} changeValue={(e)=>normalizeBody('Quantity',+e.target.value)}/>

            </div>
        </div>
        <div className='price-detail'>
            <div className='price-detail-info'>
                <p>Price</p>
                <span>Remember to factor in the costs of materials, labour, and other business expenses.</span>
            </div>
            <div className='price-info-container'>
                 <CurencyInput name={'Price'} value={body?.price} req={true}  changeValue={(e)=>normalizeBody('price',+e.target.value)}/>
            </div>
        </div>
    </div>
  )
}

export default InventoryAndPriceing