import { BrowserRouter,Routes,Route} from "react-router-dom";
import './App.css'
import PublicWrapper from "./Layouts/Public/PublicWrapper";
import './i18n'
import HomePage from "./Pages/HomePage/HomePage";
import SingUp from "./Pages/SingUp/SingUp";
import { UserContext } from "./Context/context";
import axios from "axios";
import Login from "./Pages/Login/Login";
import { useTranslation } from "react-i18next";
import { useEffect, useState } from "react";
import { jwtDecode } from "jwt-decode";
import PrivateWrapper from "./Layouts/Private/PrivateWrapper";
import SellerInfo from "./Pages/SellerInfo/SellerInfo";
import { ToastContainer} from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';



function App() {
  const [user,setUser] = useState(null)
  const [isInitialized, setIsInitialized] = useState(false);
  useEffect(()=>{
    const storedAuth = localStorage.getItem('auth');
    if (storedAuth) {
      const { accessToken, refreshToken } = JSON.parse(storedAuth);
      if (accessToken && refreshToken) {
        setUser({ ...jwtDecode(accessToken), accessToken, refreshToken });
      }
    }
    setIsInitialized(true);
  },[])
  const loginUser = (data)=>{
    localStorage.setItem('auth',JSON.stringify(data))
    if (data && (!user || user.accessToken !== data.accessToken)) {
      setUser(data);
    }
  }
  console.log(user)
  const resetUser = ()=>{
    localStorage.removeItem('auth')
    setUser(null)
  }
  return (
    <UserContext.Provider value={{ user, setUser: loginUser, resetUser,isInitialized }}>
    <div className="App">
    <ToastContainer />
     <BrowserRouter >
      <Routes>
        <Route path="/"  element={<PublicWrapper><HomePage/></PublicWrapper>}></Route>
        <Route path="/singup" element={<PublicWrapper><SingUp/></PublicWrapper>}></Route>
        <Route path="/login" element={<PublicWrapper><Login/></PublicWrapper>}></Route>
        <Route path="/sellerinfo/:id" element={<PublicWrapper><SellerInfo/></PublicWrapper>}></Route>
        <Route path="/dashboard"  element={<PrivateWrapper title={'My dashboard'}>
          <div></div>
        </PrivateWrapper>}></Route>

      </Routes>
    </BrowserRouter>
    </div>
    </UserContext.Provider>
  );
}

export default App;
