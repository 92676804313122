import React from 'react'
import './Cards.scss'
function Cards({img,text,text2}) {
  return (
    <div className='Cards'>
        <div>
          <img src={img}/>
          <span>{text}</span>
        </div>
        {text2}
    </div>
  )
}

export default Cards