import {ReactComponent as UK} from './../assets/UK.svg'
import {ReactComponent as RU} from './../assets/rus.svg'
import {ReactComponent as AM} from './../assets/Armenian.svg'
import {ReactComponent as IR} from './../assets/parsik.svg'

export const languageEnum = {
    "EN":{name:'ENG',icon:<UK/>},
    "RU":{name:'Рус',icon:<RU/>},
    'AM':{name:'Արմ',icon:<AM/>},
    "IR":{name:'فا',icon:<IR/>}
}