import React from 'react'
import {ReactComponent as RemoveIcon} from './../../../../../assets/Vector (4).svg'
import './Styles.scss'
function InputWithRemove({value,changeValue,req,type,defaultValue,styles,removeAction,showRemove}) {
  return (
    <div className='input-wrapper'>
        <input value={value} onChange={changeValue} style={styles} defaultValue={defaultValue} required={req} type={type}/>
        {showRemove&&<RemoveIcon onClick={()=>removeAction()}/>}
    </div>
  )
}

export default InputWithRemove