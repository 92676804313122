import React from 'react'
import './TopPart.scss'
import PhoneLogo from './../../../../assets/Phone.png'
import { Link } from 'react-router-dom'

function MobileTopPart() {
  return (
    <div className='Mobile-Top-Part-Container'>
    <div className='Mobile-Top-Part'>
        <div className='logo-container'>
            <span>LOGO</span>
        </div>
        <img src={PhoneLogo}/>
        <div className='buttons-container'>
        <Link to='/login'> <button className='login_btn'>Log in</button></Link>
        <Link to='/singup'>  <button className='singup_btn'>Sign up</button></Link>
        </div>
    </div>
    <div className='Mobile-left_part'>
            <div>
                <h1>Start selling with <span>Arn.am</span></h1>
                <span>Join the creative marketplace where millions of shoppers spend billions each year purchasing directly from creative entrepreneurs like you.</span>
                <button>Get started</button>
            </div>
        </div>
    </div>

  )
}

export default MobileTopPart