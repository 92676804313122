import React from 'react'
import './Footer.scss'
import types from './../../../../assets/Frame 2263.svg'
import email from './../../../../assets/email.svg'
import phone from './../../../../assets/phone.svg'
import { Link } from 'react-router-dom'

function Footer({data}) {
  return (
    <div className='footer'>
        <div className='main_info'>
            <div className='contucts'>
                <p>LOGO</p>
                <div><img src={email}/> <span>lorem@gmail.com</span></div>
                <div><img src={phone}/> <span>+374 12345678</span></div>
            </div>
            <div className='links'>
                  <div>
                    {data?.map((i,index)=><Link key={index} className='link'>{i.categories[0].title}</Link>)}
                  </div>
                  <div>
                    <Link className='link'>About us</Link>
                    <Link className='link'>Contact us</Link>
                    <Link className='link'>Sell products on abranq.am</Link>
                    <Link className='link'>Conditions of Use</Link>
                    <Link className='link'>Your account</Link>
                    <Link className='link'>Your orders</Link>
                  </div>
            </div>
        </div>
        <div className='card_types'>
            <span>© 2023</span>
             <img src={types}/>
        </div>
    </div>
  )
}

export default Footer