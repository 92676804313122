import React, { useEffect, useState } from 'react'
import './Component.scss'
import upload from './../../../assets/ID.svg'
import img from './../../../assets/Vector-file.svg'
import { uploadUrl } from '../Urls'
import closeIcon from './../../../assets/close.svg'
import api from '../../../utils/api'
function Upload({data,setData}) {
   const [fileInfo,setFilesInfo] = useState([])
  function removeFile(id){
    setFilesInfo(fileInfo.filter(i=>i.file.name!==id))
  }
  function normalizeFiles (files){
      return files?.map(i=>i?.file)
  }
   function handleFileChange(event) {
 
    const files =[...normalizeFiles(fileInfo),...Array.from(event.target.files)];
    console.log('files',files)
    const newFilesInfo = files.map(file => ({
      file,
      name: file.name,
      size: (file.size / 1024).toFixed(2) + ' KB',
      progress: 0,
      complete: false,
    }));

    setFilesInfo(newFilesInfo);
    const formData = new FormData()
     newFilesInfo.forEach((fileInfo, index) => {
    
      formData.append('files', fileInfo.file);

      api.post(uploadUrl, formData, { 
        onUploadProgress: progressEvent => {
          const percentComplete = (progressEvent.loaded / progressEvent.total) * 100;
          setFilesInfo(prevFilesInfo => {
            const updatedFilesInfo = [...prevFilesInfo];
            updatedFilesInfo[prevFilesInfo.length - newFilesInfo.length + index].progress = percentComplete;
            return updatedFilesInfo;
          });
        }
      })
      .then((response) => {
      
          setFilesInfo(prevFilesInfo => {
            const updatedFilesInfo = [...prevFilesInfo];
            updatedFilesInfo[prevFilesInfo.length - newFilesInfo.length + index].complete = true;
            return updatedFilesInfo;
          });

          setData(response)
        } 
      )
      .catch(error => {
        console.error('Upload failed', error);
      });
    });

  }
  useEffect(()=>{

  },[])
  console.log(fileInfo)
  return (
    <div className='upload-container'>
        <div className='upload-icon'>
            <label htmlFor='Document-Upload'>
                <img src={upload} alt='upload'/>
                <p>Upload <span>Passport</span> or <span>ID</span> front page</p>
                <h3>Drag your file here or <span>browse</span></h3>
                <span>400x400 or higher recommended, max 1 MB High resolution images (jpg,  png,pdf)</span>
            </label>
            <input type='file' value={fileInfo.file} name='Document-Upload' required id='Document-Upload' multiple onChange={(e)=>handleFileChange(e)}/>
        </div>
        <div className='file-container'>
            <span>Uploaded files</span>
            { fileInfo?.map((i,index)=>    <div key={index}>
            <div className='file-type'><img src={img} alt='fileIcon'/><span>{i?.file?.type?.split('/')?.[1]}</span></div>
                <div className='progress-wrapper'>
                     <span>{i?.name}</span>
                     <span>{i.size}</span>
                    <progress className={i.complete?'progress_completed':'progress'} value={i.progress} />
                        <span className='procces'>Loading</span>
                    </div>
                <div className='close-icon'><img src={closeIcon} onClick={()=>removeFile(i.file.name)} alt='close'/></div>
            </div>)}
        
        </div>
    </div>
  )
}

export default Upload