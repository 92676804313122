import React from 'react'
import './Card.scss'
import CategroyPhoto from './../../../../assets/image.png'
function Card({name}) {
  return (
    <div className='card'>
        <img src={CategroyPhoto}/>
        <div>
            <span>{name}</span>
        </div>
    </div>
  )
}

export default Card