// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.login-header {
  display: flex;
  justify-content: space-between;
  position: fixed;
  top: 0;
  padding: 24px 48px 0px 24px;
  width: 100%;
  z-index: 2;
}
.login-header > img {
  width: 24px;
  height: 24px;
}`, "",{"version":3,"sources":["webpack://./src/Pages/Login/Components/Header/header.scss"],"names":[],"mappings":"AAAA;EACI,aAAA;EACA,8BAAA;EACA,eAAA;EACA,MAAA;EACA,2BAAA;EACA,WAAA;EACA,UAAA;AACJ;AAAI;EACI,WAAA;EACA,YAAA;AAER","sourcesContent":[".login-header{\n    display: flex;\n    justify-content: space-between;\n    position: fixed;\n    top: 0;\n    padding: 24px 48px 0px 24px ;\n    width: 100%;\n    z-index: 2;\n    >img{\n        width: 24px;\n        height: 24px;\n    } \n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
