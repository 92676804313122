import React, { useEffect, useState } from 'react'
import './Steps.scss'
import Input from '../../../../Components/Input/Input'
import Select from '../../../../Components/Select/Select'
import api from './../../../../utils/api'
import { getCategoryUrl } from '../../../../Pages/HomePage/Url'
import HardSelect from '../../../../Components/HardSelect/Select'
import { getSchemaBySubcategory, getSubCategory } from '../Urls'
function Detalis({category,setCatgeory,shcema,setSchema,normalizeBody,body,language,subCategoryes,setSubCatgeoryes,data,setData,subForSchema,setSubForSchema}) {
    const [categoryes,setCatgeoryes] = useState([])

    const [properties,setProperties] = useState([])
    console.log(data)
    useEffect(()=>{
        api.get(getCategoryUrl).then((res)=>{
             const responce = res.map(i=>{
                return {id:i?.id,title:i?.categories?.[0].title}
             })
             setCatgeoryes(responce)
             
        }).catch((e)=>console.error(e))
    },[])
    console.log(data)
    const normalizeProporties = ({inputType,inputId,i,language}) =>{
        if(inputType=='Select'){
            normalizeBody('properties',body?.properties?.map((item)=>{
                if(item?.inputId===inputId){
                    return {inputId:inputId,valueIds:[i.valueId],values:i.values}
                }else{
                    return item
                }
            }))
        }else{
            normalizeBody('properties',body?.properties?.map((item)=>{
                if(item?.inputId===inputId){
                    return {inputId:inputId,values:i}
                }else{
                    return item
                }
            }))
        }
    }
    const fetchSubcategory = (category)=>{
        setCatgeory(category)
        api.get(getSubCategory+category?.id).then((res)=>{
            const responce = res?.map(i=>{
                return {...i,title:i?.names?.[0]?.title,subCategories:i?.subCategories?.map(j=>{
                    return{...j,title:j?.names?.[0]?.title}
                })}
             })
             setSubCatgeoryes(responce)
        }).catch((e)=>console.error(e))
        
    }
    const normalizeSelectValue=(data)=>{
        return data?.map(i=>{
            return {...i,title:i?.values[0]?.title}
        })
    }
    const changeSubCategory = (item)=>{
        
        setData({...subCategoryes?.find(i=>i?.id===item?.id),title:item?.title})
    }
    const fetchSchema = (item)=>{
        setSubForSchema(item)
        api.get(getSchemaBySubcategory+item?.id).then((res)=>{
            setSchema(res)
          
            if (res?.inputs) {
                normalizeBody('properties', res.inputs);
            } else {
                console.error('Inputs are undefined or empty');
            }
            
            if (res?.id) {
                normalizeBody('schemaId', res.id);
            } else {
                console.error('ID is undefined or empty');
            }
        }).catch(e=>console.error(e))
  
    }
    const showValues = (body,data,id)=>{
         let value = body?.find(i=>i.inputId==id)
         return data?.find(i=>i.valueId==value?.valueIds?.[0])
    }
   const addTtileTranslation=(data,language)=>{
        if(!body?.titles){
            return [data]
        }
        if(!body?.titles?.some(item => item?.translation?.language === language)){
           return [...body?.titles,data]
        }else{
         return  body?.titles?.map(item => {
                if (item.translation.language === language) {
                  return data;
                }
                return item;
              }).filter(i=>i.translation.title !=='')
        }
   }
   const addTrasnlationWithValue = (array,data) =>{
   console.log(array)
          console.log(array.some(i=>i.language===data?.language))
        if(!array?.length){
            return [data]
        }
        if(!array.some(i=>i.language===data?.language)){
            return [...array,data]
        }else{
            return array.map(i=>{
                if(i.language===data.language){
                return data
                }else{
                    return i
                }
            }).filter((item)=>item?.title!=='')
        }
   }
  return (
    <div className='detalis-container'>
        <span>Tell the world all about your item and why they`ll love it.</span>
        <div className='detail'>
            <div className='detail-info'>
                <p>Brand</p>
                <span>Include keywords that buyers would use to search for your item.</span>
            </div>
            <div className='info-container'>
                <Input value={body?.brand} name={'Brand'} req={true} styles={{border: "1px solid #0B7E9B"}} changeValue={(e)=>normalizeBody('brand',e.target.value)}/>
              

            </div>
        </div>
        <div className='detail'>
            <div className='detail-info'>
                <p>Title</p>
                <span>Include keywords that buyers would use to search for your item.</span>
            </div>
            <div className='info-container'>
                <Input value={body?.titles?.find(i=>i.translation.language == language)?.translation?.title||''} name={'Product Name'} req={true} styles={{border: "1px solid #0B7E9B"}} changeValue={(e)=>normalizeBody('titles',addTtileTranslation({translation:{language:language,title:e.target.value}},language))}/>       

            </div>
        </div>
        <div className='detail'>
            <div className='detail-info'>
                <p>Category</p>
                <span>Type a two or three word description of your item to get category suggestions that will help more shoppers find it</span>
            </div>
            <div className='info-container'>
                <HardSelect name={'Categories'} value={category} req={true} data={categoryes}  styles={{border: "1px solid #0B7E9B"}} setValue={fetchSubcategory}/>
                {subCategoryes?.length?<HardSelect name={'Subcategories'} value={{...data,title:data.names?.find(i=>i.language===language)?.title}} req={true} data={subCategoryes?.map(i=>{return{...i,title:i?.names?.find(name=>name?.language===language)?.title}})}  styles={{border: "1px solid #0B7E9B"}} setValue={changeSubCategory}/>:null}
                {data?.subCategories?.length&& <HardSelect name={'Subcategories'} value={{...subForSchema,title:subForSchema.names?.find(i=>i.language===language)?.title}} req={true} data={data?.subCategories?.map(i=>{return{...i,title:i?.names?.find(name=>name?.language===language)?.title}})}  styles={{border: "1px solid #0B7E9B"}} setValue={fetchSchema}/> }
                {shcema?   shcema?.inputs?.map((i,index)=>{
                    if(i?.inputType=="Select"){
                        return <HardSelect key={index} name={i?.names?.[0].title} value={showValues(body?.properties,normalizeSelectValue(i?.values),i?.inputId)} req={true} data={normalizeSelectValue(i?.values)}  styles={{border: "1px solid #0B7E9B"}} setValue={normalizeProporties} props={i}/>
                    }else{
                        return <Input key={index} value={body?.properties?.find(item=>item?.inputId===i.inputId)?.values?.find(j=>j.language==language)?.title||''} name={i?.names?.[0].title} req={true} styles={{border: "1px solid #0B7E9B"}} changeValue={(e)=>{
                                normalizeProporties({...i,i:addTrasnlationWithValue(body?.properties?.find(item=>item?.inputId===i.inputId)?.values,{language:language,title:e.target.value})})
                        }}/>
                    }
                }):null}                
            </div>
        </div>
        <div className='detail'>
            <div className='detail-info'>
                <p>Model</p>
                <span>Write your product model, including any relevant identifiers or version numbers, to ensure customers can easily find and select the specific item they need.</span>
            </div>
            <div className='info-container'>
                <Input value={body?.model} name={'Model'} req={true} styles={{border: "1px solid #0B7E9B"}} changeValue={(e)=>normalizeBody('model',e.target.value)}/>
              

            </div>
        </div>
        <div className='detail'>
            <div className='detail-info'>
                <p>Origin</p>
                <span>Write your product origin, such as the country or region where it was manufactured, to provide customers with information about its background and quality.</span>
            </div>
            <div className='info-container'>
                <Input value={body?.origin} name={'Origin'} req={true} styles={{border: "1px solid #0B7E9B"}} changeValue={(e)=>normalizeBody('origin',e.target.value)}/>
              

            </div>
        </div>
       
    </div>
  )
}

export default Detalis