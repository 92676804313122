import React from 'react'
import LanguageBar from '../../../../Components/LanguageBar/LanguageBar'
import './header.scss'
import home from './../../../../assets/Frame 2698.svg'
import { Link } from 'react-router-dom'
function LoginHeader({color}) {
  return (
    <div className='login-header'>
       <Link to={'/'}><img src={home}  alt='home'/></Link>
        <LanguageBar color={color}/>
    </div>
  )
}

export default LoginHeader