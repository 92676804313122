import React from 'react'
import './Component.scss'
import Input from '../../../Components/Input/Input'


function Contact({contactInfo,setContactInfo}) {
 console.log(contactInfo)
  return (
    <div className='Contact-wrapper'>
        <Input name={'State'} req={true} defaultValue={contactInfo?.state}  changeValue={(e)=>setContactInfo({...contactInfo,state:e.target.value})}/>
        <Input name={'City'} req={true} defaultValue={contactInfo?.city}  changeValue={(e)=>setContactInfo({...contactInfo,city:e.target.value})}/>
        <Input name={'Address'} req={true} defaultValue={contactInfo?.address}  changeValue={(e)=>setContactInfo({...contactInfo,address:e.target.value})}/>
        <Input name={'Postal code'} req={true} defaultValue={contactInfo?.postalCode}  changeValue={(e)=>setContactInfo({...contactInfo,postalCode:e.target.value})}/>
        <Input name={'Phone number'} req={true} defaultValue={contactInfo?.mobilePhone}  changeValue={(e)=>setContactInfo({...contactInfo,mobilePhone:e.target.value})}/>
        <Input name={'Fix Phone number'} req={true} defaultValue={contactInfo?.fixPhone}  changeValue={(e)=>setContactInfo({...contactInfo,fixPhone:e.target.value})}/>
    </div>
  )
}

export default Contact