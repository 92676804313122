import React from 'react'
import './Input.scss'
function Input({name,value,changeValue,req,type,defaultValue,styles}) {
  return (
    <div className='input-container'>
        <span>{name} {req&&<span>*</span>}</span>
        <input value={value} onChange={changeValue} style={styles} defaultValue={defaultValue} required={req} type={type}/>
    </div>
  )
}

export default Input