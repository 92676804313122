import React, { useState } from 'react'
import './SellerInfo.scss'
import LoginHeader from '../Login/Components/Header/LoginHeader'
import Stepper from '../../Components/Stepper/Stepper'
import CustomStepper from '../../Components/Stepper/Stepper';
import { useContext } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { useEffect } from 'react';
import { UserContext } from '../../Context/context';
import Seller from './Components/Seller';
import api from './../../utils/api'
import Contact from './Components/Contact';
import Business from './Components/Business';
import Upload from './Components/Upload';
import { getSeller, updateBusinessInfo, updateContactInfo, updateSeller, updateSellerInfo } from './Urls';
import { type } from '@testing-library/user-event/dist/type';
import { toast } from 'react-toastify';
import OtpPopup from '../../Components/PopUps/OtpCheck/OtpPopup';
const contetName = {
    'None':1,
    'SellerInformationCompleted':2,
    'ContactInformationCompleted':3,
    'BusinessInformationCompleted':4,
    'DocumentsUploaded':4
}
const url={
    1:updateSellerInfo,
    2:updateContactInfo,
    3:updateBusinessInfo,
    4:updateSeller
}
function SellerInfo() {
    const [step,setStep] = useState(1)
    const {user,isInitialized} = useContext(UserContext)
    const [data,setData] = useState(null)
    const [sellerInfo,setSellerInfo] = useState({})
    const [contactInfo,setContactInfo] = useState({})
    const [businessInfo,setBuinseesInfo] = useState({})
    const [uploadDocuments,setUploadDocuments] = useState([])
    const [showPopUp,setShowPopup] = useState(false)
    const [codeId,setCodeId] = useState('')
    const {id} = useParams()
    console.log(id)
    const navigate = useNavigate()
    useEffect(()=>{
        if(isInitialized&&!user){
            return navigate("/");
        }
    },[user,isInitialized])
    
    function getSellerData () {
      api.get(getSeller).then((res)=>{
        setData(res)
        setSellerInfo({
            firstName:res?.firstName,
            lastName:res?.lastName,
            fatherName:res?.fatherName,
            dateOfBirth:res?.dateOfBirth,
            type:res?.type,
            ssn:res?.ssn,
            documentNumber:res?.documentNumber,
            taxpayerIdentificationNumber:res?.taxpayerIdentificationNumber
        })
        setContactInfo({
            city:res?.city,
            state:res?.state,
            address:res?.address,
            postalCode:res?.postalCode,
            fixPhone:res?.fixPhone,
            mobilePhone:res?.mobilePhone
        })
        setBuinseesInfo({
            Owner:res?.owner,
            CardNumber:res?.cardNumber,
            Logo:res?.logo,
            CompanyName:res?.companyName
        })
        setUploadDocuments(res?.documents)
    }).catch(e=>console.error(e))
    }
    useEffect(()=>{
        setStep(contetName[id] || 1);
        getSellerData()
    },[id])
    const stepperDetails = [
        {
          count: 1,
          title: "Seller information"
        },
        {
          count: 2,
          title: "Contact Information"
        },
        {
          count: 3,
          title: "Business information"
        },
        {
          count: 4,
          title: "Upload documents"
        }
      ];
      const Content = {
        1:<Seller data={data} setSellerInfo={setSellerInfo} sellerInfo={sellerInfo}/>,
        2:<Contact data={data} setContactInfo={setContactInfo} contactInfo={contactInfo}/>,
        3:<Business data={data} businessInfo={businessInfo} setBuinseesInfo={setBuinseesInfo}/>,
        4:<Upload data={data} setData={setData}/>
      }
      const contentData = {
        1:sellerInfo,
        2:contactInfo,
        3:normalizeBusinesData(businessInfo),
        4:data
      }
      function handleSubmit(event){
        event.preventDefault();
        api.put(url[step],contentData[step]).then(res=>{
           if(step<=3){
            setStep(step+1)
            navigate(`/sellerinfo/${res.status}`)
          }
            if(step>3){
              setCodeId(res)
              console.log(res)
              setShowPopup(true)
            }
          }).catch(e=>console.error(e))
      }
      function normalizeBusinesData(obj){
         const formData = new FormData()
          for(let i in obj){
            formData.append(i,obj[i])
          }
          return formData
      }
  return (
    <>
      <LoginHeader color={'#0B7E9B'}/>
      <div className='seller_info-wrapper'>
        <div className='components_wrapper'> 
          <Stepper stepperDetails={stepperDetails} step={step}/>
          <form className='content_wrapper' onSubmit={handleSubmit}>
                {Content[step]}
            <div className='btn-container'><button className='back_btn' type='button' onClick={()=>{
                if(step!==1){
                setStep(step-1)
                }else{
                    console.log(step)
                }
                }}>Back</button> <button type='submit' className='next_btn' >Next</button></div>
          </form>
        </div>
            <span>© Copyright Arn.am All Rights Reserved</span>
      </div>
      {showPopUp&&<OtpPopup setCodeId={setCodeId} isPhoneVerified={data?.isPhoneVerified} setShowPopup={setShowPopup} codeId={codeId} phoneNumber = {data?.mobilePhone?.slice(data?.mobilePhone?.length-4)} getSellerData={getSellerData}/>}
    </>
  
  )
}

export default SellerInfo