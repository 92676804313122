import React, { useRef, useState,useEffect } from 'react'
import './Select.scss'
function Select({data,value,setValue,name,req,styles}) {
    const ref = useRef(null)
    const [showDropDown,setShowDropDown] = useState(false)
    useEffect(() => {
        function handleClickOutside(event) {
          if (ref.current && !ref.current.contains(event.target)) {
            setShowDropDown(false);
          }
        }
    
        document.addEventListener('mousedown', handleClickOutside);
        return () => {
          document.removeEventListener('mousedown', handleClickOutside);
        };
      }, [setShowDropDown]);
  return (
    <div className='select_container'>
        <span>{name} {req&&<span>*</span>}</span>
        <div className='select' style={styles} onClick={()=>setShowDropDown(true)}>
             <input value={value}  required={req} readOnly/>
             <svg width="12" height="8" viewBox="0 0 12 8" fill="none" xmlns="http://www.w3.org/2000/svg">
<path d="M9.87656 0.999531L5.99656 4.87953L2.11656 0.999531C1.72656 0.609531 1.09656 0.609531 0.706562 0.999531C0.316563 1.38953 0.316563 2.01953 0.706562 2.40953L5.29656 6.99953C5.68656 7.38953 6.31656 7.38953 6.70656 6.99953L11.2966 2.40953C11.6866 2.01953 11.6866 1.38953 11.2966 0.999531C10.9066 0.619531 10.2666 0.609531 9.87656 0.999531Z" fill="#6D6D73"/>
</svg>
        </div>
        {showDropDown&&<div className='drop-down' ref={ref} onClick={()=>setShowDropDown(false)}>
            {data?.map((i,index)=><div key={index}
             onClick={()=>{
                setValue(i)
                setShowDropDown(false)
            }}>
            <span>{i}</span>
            </div>)}
        </div>}
    </div>
  )
}

export default Select