// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.language-bar {
  position: relative;
  display: flex;
  flex-direction: column;
  align-items: center;
}
.language-bar .language {
  display: flex;
  flex-direction: row;
  gap: 6px;
  align-items: center;
}
.language-bar .language > span {
  font-family: Montserrat;
  font-size: 16px;
  font-weight: 400;
  line-height: 19.5px;
  text-align: start;
  color: #FFFFFF;
  cursor: pointer;
}
.language-bar .language > img {
  width: 7.18px;
  height: 4.59px;
  cursor: pointer;
}
.language-bar .language-dropdown {
  display: flex;
  flex-direction: column;
  border: 1px solid #F1F1F2;
  width: 70px;
  background: #FFFFFF;
  position: absolute;
  top: 26px;
  border-radius: 8px;
  gap: 2px;
}
.language-bar .language-dropdown > span {
  text-align: center;
  padding: 6px 15px 6px 15px;
  font-family: Montserrat;
  font-size: 14px;
  font-weight: 400;
  line-height: 20px;
  letter-spacing: 0.001em;
  border-radius: 6px;
  cursor: pointer;
}
.language-bar .language-dropdown > span:hover {
  background-color: #F4F3F7;
}`, "",{"version":3,"sources":["webpack://./src/Components/LanguageBar/LanguageBar.scss"],"names":[],"mappings":"AAAA;EACI,kBAAA;EACA,aAAA;EACA,sBAAA;EACA,mBAAA;AACJ;AAAI;EACI,aAAA;EACA,mBAAA;EACA,QAAA;EACA,mBAAA;AAER;AADQ;EACI,uBAAA;EACA,eAAA;EACA,gBAAA;EACA,mBAAA;EACA,iBAAA;EACA,cAAA;EACA,eAAA;AAGZ;AADQ;EACI,aAAA;EACA,cAAA;EACA,eAAA;AAGZ;AAAI;EACI,aAAA;EACA,sBAAA;EACA,yBAAA;EACA,WAAA;EACA,mBAAA;EACA,kBAAA;EACA,SAAA;EACA,kBAAA;EACA,QAAA;AAER;AADQ;EACI,kBAAA;EACA,0BAAA;EACA,uBAAA;EACA,eAAA;EACA,gBAAA;EACA,iBAAA;EACA,uBAAA;EACA,kBAAA;EACA,eAAA;AAGZ;AAAQ;EACI,yBAAA;AAEZ","sourcesContent":[".language-bar{\n    position: relative;\n    display: flex;\n    flex-direction: column;\n    align-items: center;\n    .language{\n        display: flex;\n        flex-direction: row;\n        gap: 6px;\n        align-items: center;\n        >span{\n            font-family: Montserrat;\n            font-size: 16px;\n            font-weight: 400;\n            line-height: 19.5px;\n            text-align: start;\n            color: #FFFFFF;\n            cursor: pointer;\n        }\n        >img{\n            width: 7.18px;\n            height: 4.59px;\n            cursor: pointer;\n        }\n    }\n    .language-dropdown{\n        display: flex;\n        flex-direction: column;\n        border: 1px solid #F1F1F2;\n        width: 70px;\n        background: #FFFFFF;\n        position: absolute;\n        top: 26px;\n        border-radius: 8px;\n        gap: 2px;\n        >span{\n            text-align: center;\n            padding: 6px 15px 6px 15px;\n            font-family: Montserrat;\n            font-size: 14px;\n            font-weight: 400;\n            line-height: 20px;\n            letter-spacing: 0.001em;\n            border-radius: 6px;\n            cursor: pointer;\n\n        }\n        >span:hover{\n            background-color: #F4F3F7;\n        }\n    }\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
