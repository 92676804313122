// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.right-part {
  display: flex;
  flex-direction: column;
  min-height: 100vh;
  justify-content: center;
  align-items: center;
  width: 100%;
  background: linear-gradient(119.41deg, #5D50C0 0%, #5D51C0 6.67%, #5C54C1 13.33%, #5B59C3 20%, #5961C5 26.67%, #566AC8 33.33%, #5376CB 40%, #5082CF 46.67%, #4D8FD3 53.33%, #4A9CD7 60%, #47A7DA 66.67%, #44B1DD 73.33%, #42B8E0 80%, #41BEE1 86.67%, #40C1E2 93.33%, #40C2E2 100%);
  padding: 67px 48px 24px 48px;
}
.right-part > div {
  display: flex;
  flex-direction: column;
  width: 100%;
  gap: 147px;
  align-items: center;
}
.right-part > div > span {
  font-family: Montserrat;
  font-size: 48px;
  font-weight: 700;
  line-height: 58.51px;
  text-align: center;
  color: #FFFFFF;
}
.right-part > div > img {
  max-height: 487px;
  max-width: 542px;
  width: 100%;
}`, "",{"version":3,"sources":["webpack://./src/Pages/Login/Components/Right/RightPart.scss"],"names":[],"mappings":"AAAA;EACI,aAAA;EACA,sBAAA;EACA,iBAAA;EACA,uBAAA;EACA,mBAAA;EACA,WAAA;EAEA,mRAAA;EACA,4BAAA;AAAJ;AACI;EACI,aAAA;EACA,sBAAA;EACA,WAAA;EACA,UAAA;EACA,mBAAA;AACR;AAAQ;EACI,uBAAA;EACA,eAAA;EACA,gBAAA;EACA,oBAAA;EACA,kBAAA;EACA,cAAA;AAEZ;AACQ;EACI,iBAAA;EACA,gBAAA;EACA,WAAA;AACZ","sourcesContent":[".right-part{\n    display: flex;\n    flex-direction: column;\n    min-height: 100vh;\n    justify-content: center;\n    align-items: center;\n    width: 100%;\n   \n    background: linear-gradient(119.41deg, #5D50C0 0%, #5D51C0 6.67%, #5C54C1 13.33%, #5B59C3 20%, #5961C5 26.67%, #566AC8 33.33%, #5376CB 40%, #5082CF 46.67%, #4D8FD3 53.33%, #4A9CD7 60%, #47A7DA 66.67%, #44B1DD 73.33%, #42B8E0 80%, #41BEE1 86.67%, #40C1E2 93.33%, #40C2E2 100%);\n    padding: 67px 48px 24px 48px;\n    >div{\n        display: flex;\n        flex-direction: column;\n        width: 100%;\n        gap: 147px;\n        align-items: center;\n        >span{\n            font-family: Montserrat;\n            font-size: 48px;\n            font-weight: 700;\n            line-height: 58.51px;\n            text-align: center;\n            color: #FFFFFF;\n\n        }\n        >img{\n            max-height: 487px;\n            max-width: 542px;\n            width: 100%;\n        }\n    }\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
