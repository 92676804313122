// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.input-wrapper {
  display: flex;
  flex-direction: row;
  align-items: center;
  max-width: 344px;
  width: 100%;
  justify-content: space-between;
  border: 1px solid #0B7E9B;
  border-radius: 4px;
  padding: 10px 12px 10px 16px;
  gap: 8px;
}
.input-wrapper > input {
  border: none;
  font-family: Montserrat;
  font-size: 14px;
  font-weight: 400;
  line-height: 20px;
  letter-spacing: 0.0025em;
  text-align: start;
  outline: none;
  width: 100%;
}
.input-wrapper > svg {
  cursor: pointer;
}`, "",{"version":3,"sources":["webpack://./src/Layouts/Private/AddProduct/Components/Input/Styles.scss"],"names":[],"mappings":"AAAA;EACI,aAAA;EACA,mBAAA;EACA,mBAAA;EACA,gBAAA;EACA,WAAA;EACA,8BAAA;EACA,yBAAA;EACA,kBAAA;EACA,4BAAA;EACA,QAAA;AACJ;AAAI;EACI,YAAA;EACA,uBAAA;EACA,eAAA;EACA,gBAAA;EACA,iBAAA;EACA,wBAAA;EACA,iBAAA;EACA,aAAA;EACA,WAAA;AAER;AACI;EACI,eAAA;AACR","sourcesContent":[".input-wrapper{\n    display: flex;\n    flex-direction: row;\n    align-items: center;\n    max-width:344px;\n    width: 100%;\n    justify-content: space-between;\n    border: 1px solid #0B7E9B;\n    border-radius: 4px;\n    padding: 10px 12px 10px 16px;\n    gap: 8px;\n    >input{\n        border: none;\n        font-family: Montserrat;\n        font-size: 14px;\n        font-weight: 400;\n        line-height: 20px;\n        letter-spacing: 0.0025em;\n        text-align: start;\n        outline: none;\n        width: 100%;\n        \n    }\n    >svg{\n        cursor: pointer;\n    }\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
