import React from 'react'
import './RightPart.scss'
import logo from './../../../../assets/Untitled-1 2 (1).svg'
function RightPart() {
  return (
    <div className='right-part'>
     
        <div>
         <span>Welcome to Arn.am</span>
        <img src={logo} alt='logo'/>
        </div>
       
    </div>
  )
}

export default RightPart