import React from 'react'
import './Header.scss'
import { Link } from 'react-router-dom'

function Header() {
  return (
    <div className='header'>
        <h1>LOGO</h1>
        <div>
          <Link to='/login'> <button className='login_btn'>Log in</button></Link>
           <Link to='/singup'>  <button className='singup_btn'>Sign up</button></Link>
          
        </div>
    </div>
  )
}

export default Header