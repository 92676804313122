import React, { useState } from 'react'
import './SideBar.scss'
import icon from './../../../assets/double_arrow.svg'
import  { ReactComponent as DashboardIcon } from './../../../assets/dashboard.svg'
import  { ReactComponent as ShopingIcon} from './../../../assets/shopping_cart.svg'
import  { ReactComponent as ShopingBagIcon } from './../../../assets/shopping_bag.svg'
import  { ReactComponent as ReportIcon } from './../../../assets/waring.svg'
import  { ReactComponent as ChartIcon } from './../../../assets/leaderboard.svg'
import  { ReactComponent as GuidIcon } from './../../../assets/Group (1).svg'
// import  { ReactComponent as DashboardIcon } from './../../../assets/dashboard.svg'
function SideBar() {
  const [showFull,setShowFull] = useState(false)
  const topLinks = [
    {path:'',icon:<DashboardIcon className="icon"/>,name:'My dashboard'},
    {path:'',icon:<ShopingBagIcon className="icon"/>,name:'Products'},
    {path:'',icon:<ShopingIcon className="icon"/>,name:'Orders & Shipping'},
    {path:'',icon:<ReportIcon className="icon"/>,name:'Reports'},
    {path:'',icon:<ChartIcon className="icon"/>,name:'Stats'},
    {path:'',icon:<GuidIcon className="icon"/>,name:'Guide'},
    
  ]
  return (
    <div className='SideBar' style={showFull?{maxWidth:'72px'}:{}}>
      <div className='logo'>
      {!showFull?<h1>Arn.am</h1>:<h1>A</h1>}
      </div>
      <div className='arrow-container'>
        <img src={icon} alt='icon' onClick={()=>setShowFull(!showFull)}/>
      </div>
      <div className='nav_bar'>
        {topLinks.map((i,key)=> <div className='link' key={key}>
           {i.icon}
         {!showFull&&<span className='title'>{i.name}</span>}
         </div>)}
        
       
      </div>
    </div>
  )
}

export default SideBar