import React, { useEffect, useState } from 'react'
import './HomePage.scss'
import Header from './Components/Header/Header'
import TopPart from './Components/TopPart/TopPart'
import Card from './Components/Cards/Card'
import Cards from './Components/Cards2/Cards'
import icon1 from './../../assets/image 93.svg'
import icon3 from './../../assets/image 94.svg'
import icon2 from './../../assets/Group 2553.svg'
import Footer from './Components/footer/Footer'
import { getCategoryUrl } from './Url'
import  Photo  from './../../assets/OT11BI1 1 (1).svg';
import axios from 'axios'
import MobileTopPart from './Components/TopPart/MobileTopPart'
import useResponsiveWidth from '../../utils/width'
import Slider from "react-slick";
import "react-responsive-carousel/lib/styles/carousel.min.css"; // requires a loader
import { Carousel } from 'react-responsive-carousel';
function HomePage() {
    const [category,setCategory] = useState([])
    useEffect(()=>{
        axios.get(getCategoryUrl).then((res)=>setCategory(res?.data)).catch((e)=>console.error(e))
    },[])
  return (
    <div className='home'>
       {useResponsiveWidth()>600?<Header/>:null}
       {useResponsiveWidth()>600?<TopPart/>:null}
        {useResponsiveWidth()<600?<MobileTopPart/>:null}
        <div className='container'>
            <div className='category_container'>
                <p>What can you sell?</p>
                <div><Card name={'Toys'}/> <Card name={'Toys'}/> <Card name={'Toys'}/></div>
            </div>
            <div className='info-container'>
                <div>
                    <div className='left_part'>
                        <p>Simple, powerful tools</p>
                        <span>Whether you're a seller looking to expand your reach or a buyer seeking a world of unique products, Abranq.am invites you to start your journey today. Explore, connect, and experience the joy of a truly global shopping experience.</span>
                        <span>Whether you're a buyer or a seller, trust is paramount. Abranq.am ensures a secure and reliable platform for transactions. Shop with confidence, knowing that your purchases are protected, and sellers can focus on delivering quality products without the hassle of complex logistics.</span>
                        <span>Spend less time managing your shop and more time on the fun stuff. We have tools to help you whether you’re just getting started or about to make your ten-thousandth sale.</span>

                    </div>
                    
                </div>
                <img src={Photo}/>
            </div>
         
        </div>
        <div className='poster'>
             <div>
                <span>Ready to start selling?</span>
                <button>Get started</button>
             </div>
        </div>
        <div className='container'>
             <div className='comunication-part'>
                    <div className='text_part'><p>Help when you need it</p><span>We’re committed to helping our  sellers thrive, with support and education for shops big and small.</span></div>
                    <div className='content_part'>
                        <Cards img={icon3} text={'Talk to us'} text2={'Choose the communication method that suits you best: email or phone call. Your satisfaction is our priority, and our dedicated support team is ready to provide the assistance you need.'}/>
                        <Cards img={icon2} text={'Seller newsletter'} text2={"Subscribe to the Abranq.am newsletter and unlock a treasure trove of valuable insights tailored to elevate your shop's performance."}/>
                        <Cards img={icon1} text={'Get advice'} text2={'Use social media to showcase your products, engage with your audience, and drive traffic to your online store. Choose platforms that align with your target audience.'} />
                    </div>

             </div>
        </div>
        <div className='poster'>
             <div>
                <span>Still have more questions?
                <span>Feel free to contact us.</span>
                </span>
                <button>Contact us</button>
             </div>
        </div>
        <Footer data={category}/>
    </div>
  )
}

export default HomePage