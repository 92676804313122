import React from 'react'
import './TopPart.scss'
import PhoneLogo from './../../../../assets/Phone.png'
import BackGround from './../../../../assets/Rectangle 342 (1).png'
import Vector1 from './../../../../assets/Group 2544 (1).png'
function TopPart() {
  return (
    <div className='toppart_container'>
        <div className='left_part'>
            <div>
                <h1>Start selling with <span>Arn.am</span></h1>
                <span>Join the creative marketplace where millions of shoppers spend billions each year purchasing directly from creative entrepreneurs like you.</span>
                <button>Get started</button>
            </div>
        </div>
        <div className='right_part'>
             <img src={BackGround} alt='background' className='background'/>
            <img src={PhoneLogo} className='phone_logo'/>
            <img  src={Vector1} className='vector1'/>
             
        </div>
    </div>
  )
}

export default TopPart