import React, { useContext, useEffect, useState } from 'react'
import { UserContext } from '../../Context/context'
import { useNavigate } from 'react-router-dom'
import axios from 'axios'
import SideBar from './SideBar/SideBar'
import {ReactComponent as PlusIcon} from './../../assets/plusVector.svg'
import {ReactComponent as NotificationIcon} from './../../assets/notifications_FILL1_wght400_GRAD0_opsz24 2.svg'
import {ReactComponent as MassageIcon} from './../../assets/massage.svg'

import './PrivateWrapper.scss'
import AddProduct from './AddProduct/AddProduct'
function PrivateWrapper({children,title}) {
    const {user,isInitialized} = useContext(UserContext)
    const navigate = useNavigate()
    const [showPanel,setShowPanel] = useState(false)
    useEffect(()=>{
        if(isInitialized&&!user){
            return navigate("/");
        }
    },[user,isInitialized])
    console.log(user)
    useEffect(() => {
      console.log("Image source:", user?.picture);
  }, [user]);
  return (
    <div className='private-wrapper'>
      <SideBar/>
      {!showPanel?<div className='main-wrapper'>
       <div className='head'>
        <span>{title}</span>
        <div className='action-bar'>
          <button  onClick={()=>setShowPanel(!showPanel)}> 
            <PlusIcon/>
            <span>Add product</span>
          </button>
          <div>
            <NotificationIcon/>
            <MassageIcon/>
          </div>
          {user?.picture&&<img src={user?.picture} alt='pic'/>}
        </div>
       </div>
       <div className='children-wrapper'>
       {children}
       </div>
      </div>:
      <AddProduct setShowPanel={setShowPanel}/>}
    </div>

  )
}

export default PrivateWrapper