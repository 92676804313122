import React from 'react'
import './Stepper.scss'

function Stepper({stepperDetails,step}) {
  return (
    <div className='Stepper-Wrapper'>
          {stepperDetails.map((i,index)=>
          <div className={step===i.count?'selected_Step':step>i.count?'done_Step':'Step'} key={index}>
            <div></div>
            <span>{i.title}</span>
        </div>)}
        <div className='line'></div>
    </div>
  )
}

export default Stepper