import axios from 'axios';
import i18n from 'i18next';
import { initReactI18next } from 'react-i18next';

const url = process.env.REACT_APP_API_URI

async function addResourse() {
    try {
      let  resources = await axios.get(`https://configuration.${url}/Configuration/v1/GetLanguages`)
        return resources.data
    } catch (error) {
        console.error(error)
        return 'en'
    }

}


i18n.use(initReactI18next).init({
    fallbackLng: await addResourse()||'EN',
    debug: true,
    resources: {
        ab: { translation: {} },
        en: { translation: {} },
    
    },
    lng: localStorage.getItem('i18nextLng') || 'EN',
    interpolation: {
        escapeValue: false,
    },
});

export default i18n;