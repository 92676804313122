// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.Cards {
  display: flex;
  flex-direction: column;
  gap: 16px;
  max-width: 464px;
  width: 100%;
}
.Cards > div {
  display: flex;
  align-items: center;
  gap: 17px;
}
.Cards > div > span {
  font-family: Montserrat;
  font-size: 24px;
  font-weight: 700;
  line-height: 29.26px;
  text-align: start;
  color: #0B7E9B;
}
.Cards > span {
  font-family: Montserrat;
  font-size: 16px;
  font-weight: 400;
  line-height: 19.5px;
  text-align: start;
}`, "",{"version":3,"sources":["webpack://./src/Pages/HomePage/Components/Cards2/Cards.scss"],"names":[],"mappings":"AAAA;EACI,aAAA;EACA,sBAAA;EACA,SAAA;EACA,gBAAA;EACA,WAAA;AACJ;AAAI;EACI,aAAA;EACA,mBAAA;EACA,SAAA;AAER;AADQ;EACI,uBAAA;EACA,eAAA;EACA,gBAAA;EACA,oBAAA;EACA,iBAAA;EACA,cAAA;AAGZ;AAAI;EACI,uBAAA;EACI,eAAA;EACA,gBAAA;EACA,mBAAA;EACA,iBAAA;AAEZ","sourcesContent":[".Cards{\n    display: flex;\n    flex-direction: column;\n    gap: 16px;\n    max-width: 464px;\n    width: 100%;\n    >div{\n        display: flex;\n        align-items: center;\n        gap: 17px;\n        >span{\n            font-family: Montserrat;\n            font-size: 24px;\n            font-weight: 700;\n            line-height: 29.26px;\n            text-align: start;\n            color: #0B7E9B;\n        }\n    }\n    >span{\n        font-family: Montserrat;\n            font-size: 16px;\n            font-weight: 400;\n            line-height: 19.5px;\n            text-align: start;\n\n    }\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
