import React from 'react'
import './OtpPopup.scss'
import { useState,useEffect } from 'react';
import {ReactComponent as RemoveIcon} from './../../../assets/Remove.svg'
import {ReactComponent as SmsIcon} from './../../../assets/Group 2487.svg'
import {ReactComponent as SmsIconTwo} from './../../../assets/Group 2488.svg'
import OtpInput from 'react-otp-input';
import { sendOtp, updateOtp } from '../../../Pages/SellerInfo/Urls';
import api from './../../../utils/api'
import { toast } from 'react-toastify';
import { useNavigate } from 'react-router-dom';

function OtpPopup({setShowPopup,codeId,phoneNumber,getSellerData,isPhoneVerified,setCodeId}) {
    const [seconds, setSeconds] = useState(30);
    const [otp, setOtp] = useState('');
    const [verefide,setVerefide] = useState(false)
    const navigate = useNavigate()
 console.log(codeId)
    useEffect(() => {
      if (seconds > 0) {
        const interval = setInterval(() => {
          setSeconds(prevSeconds => prevSeconds - 1);
        }, 1000);
  
        return () => clearInterval(interval);
      }
    }, [seconds]);
    const handleSendOtp = (body)=>{
        api.post(sendOtp,body).then(res=>{getSellerData()
            setVerefide(true)
        }).catch(()=>toast.error('some went a wrong'))
    }
    const handleResend = ()=>{
        api.put(updateOtp).then(res=>{setCodeId(res)
            setSeconds(30)
        }).catch(()=>toast.error('some wnet a wrong'))
    }
  return (
    <div className='Otp-layout'>
        <div className='otp-container'>
             <div className='remove-btn'>
                <RemoveIcon onClick={()=>setShowPopup(false)}/>
             </div>
             {!verefide?<div className='main-container'>
                <SmsIcon/>
                <div className='otp-part'>
                    <div className='info-part'>
                        <h1>SMS confirmation</h1>
                        <div>
                            <span>{`Please verify your phone ending ${phoneNumber}`}</span>
                             <span>A text message with a verification code has been sent to you</span>
                        </div>
                    </div>
                    <div className='Otp'>
                    <OtpInput
                        value={otp}
                        onChange={setOtp}
                        numInputs={6}
                        renderInput={(props) => <input {...props} />}
                    />
                    <span>{`You Can request a new code after ${seconds} seconds`}</span>
                    </div>
             
                </div>
                <div className='btn-container'><button className='white'disabled={seconds} onClick={()=>handleResend()} >Re- send Code</button> <button  className='green' disabled={otp?.length!==6} onClick={()=>handleSendOtp({codeId,code:otp})}>Verify</button></div>
             </div>:
             <div className='verefide'>
                <div className='info'>
                <SmsIconTwo/>
                <h1>Phone Number verified</h1>
                </div>
                <div className='btn'><button onClick={()=>navigate('/login')}>ok</button></div>
             </div>}
        </div>
    </div>
  )
}

export default OtpPopup