import React, { Children } from 'react'
import './PublicWrapper.scss'

function PublicWrapper({children}) {
  return (
    <>
    {children}
    </>
  )
}

export default PublicWrapper