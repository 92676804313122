import React, { useEffect,useContext, useState} from 'react'
import './Singup.scss'
import { UserContext } from '../../Context/context'
import RightPart from '../Login/Components/Right/RightPart'
import LoginHeader from '../Login/Components/Header/LoginHeader'
import Input from '../../Components/Input/Input'
import google from './../../assets/google.svg'
import { Link, json, useLocation, useNavigate } from 'react-router-dom'
import useResponsiveWidth from '../../utils/width'
import { googleUrl,SingUpUrl} from './Url'
import axios from 'axios'
import { getSeller } from '../Login/Url'
import api from './../../utils/api'
import { toast } from 'react-toastify'


function SingUp() {
   
    const location = useLocation()
    const navigate = useNavigate()
    const { setUser, user } = useContext(UserContext);

    const [singUpBody,setSingUpBody] = useState({
      "email": '',
      "isSeller": true,
      "password":'',
    })
    const getQueryParams = () => {
        return new URLSearchParams(location.search);
      };
      const queryParams = getQueryParams()
      useEffect(() => {
        const accessToken = queryParams.get('accessToken');
        const refreshToken = queryParams.get('refreshToken');
    
        if (accessToken && refreshToken) {
            setUser({ accessToken, refreshToken });
        }
    }, [queryParams]);
    
    useEffect(() => {
        if (user) {
            api.get(getSeller).then((res) => {
                if (res?.status === 'Pending') {
                    navigate('/dashboard');
                } else {
                    navigate(`/sellerinfo/${res?.status}`);
                }
            }).catch((error) => {
                console.error("API call failed:", error);
            });
        }
    }, [user?.accessToken, user?.refreshToken]);
    
    function handleGoogleLogin(url){
        axios.get(url).then((res)=>{
            window.location.href = res.data

        }).catch((e)=>{
            console.error(e)
        })
    }
    function handleSingUp(e){
       e.preventDefault()
      axios.post(SingUpUrl,singUpBody).then((e)=>{toast.success('check email')
        navigate('/login')
      }).catch((error)=>{
        toast.error(error?.response?.data?.title)})
    }
    console.log(singUpBody)
  return (
    <div className='singup-container'>
        <LoginHeader color={useResponsiveWidth()>622?'#FFFFFF':'#0B7E9B'}/>
        <div className='left_part'>
           <div className='singup'>
             <h1>Become a Seller</h1>
             <div>
                <div>
                    <p>Already have an account? <Link to={'/login'}>Log In</Link></p>
                    <button onClick={()=>handleGoogleLogin(googleUrl+window.origin+location.pathname)}>
                        <img src={google} alt='google'/>
                        <span>Sign up with Google</span>
                    </button>
                    <div>
                        <div></div>
                        <span>or</span>
                        <div></div>
                    </div>
                </div>
                <form onSubmit={handleSingUp}>
                    <Input name={'Email address'} req={true} type={'text'} required changeValue={(e)=>setSingUpBody({...singUpBody,email:e.target.value})}/>
                    <Input name={'Password'} req={true}  type={'password'} required changeValue={(e)=>setSingUpBody({...singUpBody,password:e.target.value})}/>
                    <div className='checkbox_container'>
                        <input type='checkbox'/>
                        <span>Remember Me</span>
                    </div>
                    <div className='checkbox_container'>
                        <input type='checkbox' required/>
                        <span>I agree to platform <Link>Terms of Service</Link> and <Link>Privacy Policy</Link></span>
                    </div>
                    <button type='submit'> Sing Up</button>
                </form>
             </div>
             <span>© Copyright Abranq.am All Rights Reserved</span>
           </div>
        </div>
        {useResponsiveWidth()>622&&<RightPart/>}
    </div>
  )
}

export default SingUp