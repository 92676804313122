// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.input-container {
  display: flex;
  flex-direction: column;
  gap: 8px;
  width: 100%;
}
.input-container > span {
  font-family: Montserrat;
  font-size: 14px;
  font-weight: 400;
  line-height: 20px;
  letter-spacing: 0.0025em;
  text-align: start;
}
.input-container > span > span {
  color: #F5695E;
}
.input-container > input {
  padding: 10px 12px 10px 16px;
  gap: 8px;
  border-radius: 4px;
  border: 1px solid #E4E4E5;
}`, "",{"version":3,"sources":["webpack://./src/Components/Input/Input.scss"],"names":[],"mappings":"AAAA;EACI,aAAA;EACA,sBAAA;EACA,QAAA;EACA,WAAA;AACJ;AAAI;EACI,uBAAA;EACA,eAAA;EACA,gBAAA;EACA,iBAAA;EACA,wBAAA;EACA,iBAAA;AAER;AADQ;EACI,cAAA;AAGZ;AACI;EACI,4BAAA;EACA,QAAA;EACA,kBAAA;EACA,yBAAA;AACR","sourcesContent":[".input-container{\n    display: flex;\n    flex-direction: column;\n    gap: 8px;\n    width: 100%;\n    >span{\n        font-family: Montserrat;\n        font-size: 14px;\n        font-weight: 400;\n        line-height: 20px;\n        letter-spacing: 0.0025em;\n        text-align: start;\n        >span{\n            color: #F5695E;\n        }\n\n    }\n    >input{\n        padding: 10px 12px 10px 16px;\n        gap: 8px;\n        border-radius: 4px;\n        border: 1px solid #E4E4E5\n\n    }\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
