import React, { useEffect, useState } from 'react'
import './Component.scss'
import Input from '../../../Components/Input/Input'
import Camera from './../../../assets/photo_camera.svg'
import api from './../../../utils/api'
import { getPhoto } from '../Urls'
function Business({businessInfo,setBuinseesInfo}) {
    const [file,setFile] = useState('')
    const [image,setImage] = useState(null)
    console.log(businessInfo)
    useEffect(()=>{
        api.get(getPhoto+businessInfo?.Logo?.url,{ responseType: 'blob' }).then(res=>{
            const blob = new Blob([res]);
            const url = URL.createObjectURL(blob);
            console.log('url->',url)
            setImage(url)
        }).catch((e)=>console.error(e))
    },[])

  return (
    <div className='Business-wrapper'>
        <div className='logo-wrapper'>
            <span>Company logo</span>
            <label htmlFor="fileId">
            <div>
               <img src={file?URL.createObjectURL(file):image}/>
              <div><img src={Camera}/></div>
            </div>
            </label>
            <input type='file' id='fileId' onChange={(e)=>{
                setFile(e.target.files[0])
                setBuinseesInfo({...businessInfo,Logo:e.target.files[0]})
            }} />
        </div>
        <div className='info-wrapper'>
            <Input name={'Store name'} req={true} defaultValue={businessInfo?.CompanyName} changeValue={(e)=>setBuinseesInfo({...businessInfo,CompanyName:e.target.value})}/>
            <Input name={'Card number'} req={true} defaultValue={businessInfo?.CardNumber} changeValue={(e)=>setBuinseesInfo({...businessInfo,CardNumber:e.target.value})}/>
            {/* <Input name={'Company phone number'} req={true}/> */}
            <Input name={'Account holder/owners'} req={true} defaultValue={businessInfo?.Owner} changeValue={(e)=>setBuinseesInfo({...businessInfo,Owner:e.target.value})}/>
        </div>
    </div>
  )
}

export default Business