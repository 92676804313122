import React, { useState,useRef,useEffect} from 'react'
import './LanguageBar.scss'
import { useTranslation } from 'react-i18next'
import Arrow from './../../assets/Vector.svg'
import ArrowGreen from './../../assets/VectorGreen.svg'
function LanguageBar({color}) {
    const {i18n} = useTranslation()
    const ref = useRef(null)
    const [showDropDown,setShowDropDown] = useState(false)
    const icon={
      '#FFFFFF':Arrow,
      '#0B7E9B':ArrowGreen
    }
    useEffect(() => {
        function handleClickOutside(event) {
          if (ref.current && !ref.current.contains(event.target)) {
             setShowDropDown(false);
          }
        }

        document.addEventListener('mousedown', handleClickOutside);
    
        return () => {
          document.removeEventListener('mousedown', handleClickOutside);
        };
      }, [setShowDropDown]);
  return (
    <div className='language-bar'>
        <div className='language' onClick={()=>setShowDropDown(!showDropDown)}>
            <span style={{color:color}}>{i18n.language}</span>
            <img src={icon[color]} alt='arrow' />
        </div>
        {showDropDown &&<div className='language-dropdown' ref={ref} >
            {i18n.languages.map((i,key)=><span key={key} onClick={()=>{
                i18n.changeLanguage(i)
                localStorage.setItem('i18nextLng',i)
                setShowDropDown(false)
            }}>{i}</span>)}
        </div>}
    </div>
  )
}

export default LanguageBar