import React, { useState } from "react";
import "./AddProduct.scss";
import { ReactComponent as CloseIcon } from "./../../../assets/closeBtn.svg";
import { useTranslation } from "react-i18next";
import { languageEnum } from "../../../utils/Enums";
import { ReactComponent as InfoLogo } from "../../../assets/Group 2564.svg";
import Detalis from "./Steps/Detalis";
import Photos from "./Steps/Photos";
import InventoryAndPriceing from "./Steps/InventoryAndPriceing";
import api from './../../../utils/api'
import { addProductUrl } from "./Urls";
import { toast } from "react-toastify";
import ColorAndSize from "./Steps/ColorAndSize";
const stepName = {
  1: "Details",
  2:"Inventory and pricing",
  3: "Photos",
  4: "Inventory and pricing",
};
function AddProduct({ setShowPanel }) {
  const { i18n } = useTranslation();
  const [language, setLanguage] = useState(i18n.languages[0]);
  const [step, setStep] = useState(1);
  const [category,setCatgeory] = useState('')
  const [subCategories,setSubCatgeoryes] = useState([])
  const [shcema,setSchema] = useState({})
  const [images,setImages] = useState([])
  const [data,setData] = useState({})
  const [subForSchema,setSubForSchema] = useState({})
  const [body,setBody] = useState({
    "packageSize": {
    "height": 2147483647,
    "width": 2147483647,
    "length": 2147483647
  },
     "advantages": "string",
     "disadvantages": "string",
     "weight": 0.1,
     "description": "25",
  })
  const normalizeBody = (id,value) =>{
    setBody(prevBody=>({...prevBody,[id]:value}))
}
console.log(body)
  const Steps = {
    1: <Detalis setCatgeory={setCatgeory} subForSchema={subForSchema} setSubForSchema={setSubForSchema} data={data} setData={setData} category={category} subCategoryes={subCategories} setSubCatgeoryes={setSubCatgeoryes} shcema={shcema} setSchema={setSchema} normalizeBody={normalizeBody} body={body} language={language}/>,
    2: <ColorAndSize normalizeBody={normalizeBody} body={body} setBody={setBody}/>,
    3: <Photos images={images} setImages={setImages}/>,
    4: <InventoryAndPriceing normalizeBody={normalizeBody} body={body} />,
  };
 function handleSubmit(e){
    e.preventDefault()
   if(step==4){
    const formData = new FormData()
    for(let i of images){
        formData.append('Photos',i)
    }
    formData.append('ProductModel',JSON.stringify(body))
    api.post(addProductUrl(category?.id),formData,{
      headers: {
          'Content-Type': 'multipart/form-data',
          'accept': 'application/json'
      }
  }).then((res)=>{
    toast.success(res)
    setShowPanel(false)
  }).catch((e)=>toast.error('some wnet a wrong'))
   }else{
    setStep(step + 1)
   }

 }

  return (
    <div className="add-product-wrapper">
      <div className="close-action">
        <span>Add product</span>
        <CloseIcon onClick={() => setShowPanel(false)} />
      </div>
      <div className="language-wrapper">
        {i18n?.languages.map((i, key) => (
          <div
            key={key}
            className={
              i !== language ? "language-item" : "selected-language-item"
            }
            onClick={() => setLanguage(i)}
          >
            {languageEnum[i]?.icon}
            <span>{languageEnum[i]?.name}</span>
          </div>
        ))}
      </div>
      <div className="add-product-container">
        <form className="add-product-body" onSubmit={handleSubmit} >
          <div className="add-product-steps">
            <div className="step-header">
              <p>{stepName[step]}</p>
              <span>View a Preview</span>
            </div>
            {Steps[step]}
          </div>
          <div className="add-product-btn">
            <button className="save-btn" type="button">Save as draft</button>
            {step > 1 && (
              <button
               type="button"
                className="previous-btn"
                onClick={() => {
                  if (step > 1) {
                    setStep(step - 1);
                  } else {
                  }
                }}
              >
                Previous
              </button>
            )}
            <button className="next-btn" type="submit">
              Next
            </button>
          </div>
        </form>
        <div className="language-info">
          <InfoLogo />
          <span>
            When adding products here, do not ignore to fill in all the required
            fields completely and follow the product adding rules. If you want
            your product to be visible to users in all languages, you must fill
            in your product information for all languages in the tab.
          </span>
        </div>
      </div>
    </div>
  );
}

export default AddProduct;
