// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../node_modules/css-loader/dist/runtime/api.js";
import ___CSS_LOADER_GET_URL_IMPORT___ from "../node_modules/css-loader/dist/runtime/getUrl.js";
var ___CSS_LOADER_URL_IMPORT_0___ = new URL("./../public/Montserrat-VariableFont_wght.ttf", import.meta.url);
var ___CSS_LOADER_URL_IMPORT_1___ = new URL("./../public/Poppins-Thin.ttf", import.meta.url);
var ___CSS_LOADER_URL_IMPORT_2___ = new URL("./../public/Roboto-Bold.ttf", import.meta.url);
var ___CSS_LOADER_URL_IMPORT_3___ = new URL("./../public/Inter-VariableFont_slnt,wght.ttf", import.meta.url);
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
var ___CSS_LOADER_URL_REPLACEMENT_0___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_0___);
var ___CSS_LOADER_URL_REPLACEMENT_1___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_1___);
var ___CSS_LOADER_URL_REPLACEMENT_2___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_2___);
var ___CSS_LOADER_URL_REPLACEMENT_3___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_3___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.App {
  min-height: 100%;
  width: 100%;
  /* box-sizing: border-box; */
}
* {
  box-sizing: border-box;
}


@font-face {
  font-family: "Montserrat";
  font-weight: 400;
  src: url(${___CSS_LOADER_URL_REPLACEMENT_0___}) format(truetype);
}

@font-face {
  font-family: "Poppins";
  font-weight: 400;
  src: url(${___CSS_LOADER_URL_REPLACEMENT_1___}) format(truetype);
}
@font-face {
  font-family: "Roboto";
  font-weight: 400;
  src: url(${___CSS_LOADER_URL_REPLACEMENT_2___}) format(truetype);
}
@font-face {
  font-family: "Inter";
  font-weight: 400;
  src: url(${___CSS_LOADER_URL_REPLACEMENT_3___}) format(truetype);
}`, "",{"version":3,"sources":["webpack://./src/App.css"],"names":[],"mappings":"AAAA;EACE,gBAAgB;EAChB,WAAW;EACX,4BAA4B;AAC9B;AACA;EACE,sBAAsB;AACxB;;;AAGA;EACE,yBAAyB;EACzB,gBAAgB;EAChB,6DAAyE;AAC3E;;AAEA;EACE,sBAAsB;EACtB,gBAAgB;EAChB,6DAAyD;AAC3D;AACA;EACE,qBAAqB;EACrB,gBAAgB;EAChB,6DAAwD;AAC1D;AACA;EACE,oBAAoB;EACpB,gBAAgB;EAChB,6DAA0E;AAC5E","sourcesContent":[".App {\n  min-height: 100%;\n  width: 100%;\n  /* box-sizing: border-box; */\n}\n* {\n  box-sizing: border-box;\n}\n\n\n@font-face {\n  font-family: \"Montserrat\";\n  font-weight: 400;\n  src: url(\"./../public/Montserrat-VariableFont_wght.ttf\") format(truetype);\n}\n\n@font-face {\n  font-family: \"Poppins\";\n  font-weight: 400;\n  src: url(\"./../public/Poppins-Thin.ttf\") format(truetype);\n}\n@font-face {\n  font-family: \"Roboto\";\n  font-weight: 400;\n  src: url(\"./../public/Roboto-Bold.ttf\") format(truetype);\n}\n@font-face {\n  font-family: \"Inter\";\n  font-weight: 400;\n  src: url(\"./../public/Inter-VariableFont_slnt\\,wght.ttf\") format(truetype);\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
