import React, { useState } from 'react'
import './Component.scss'
import Input from '../../../Components/Input/Input'
import Select from '../../../Components/Select/Select'
import { type } from '@testing-library/user-event/dist/type'
function Seller({data,sellerInfo,setSellerInfo}) {
    console.log(sellerInfo)
    function isValidDate(dateString){
        const date = new Date(dateString);
        return date instanceof Date && !isNaN(date);
    }
    const setValue = (i) =>{
        setSellerInfo({...sellerInfo,type:i})
    }
    console.log(isValidDate(data?.dateOfBirth))
  return (
    <div className='Seller-container'>
        <Input name={'First Name'} req={true} defaultValue={data?.firstName} changeValue={(e)=>setSellerInfo({...sellerInfo,firstName:e.target.value})}/>
        <Input name={'Last Name'}  req={true} defaultValue={data?.lastName} changeValue={(e)=>setSellerInfo({...sellerInfo,lastName:e.target.value})} />
        <Input name={'Father Name'}  req={true} defaultValue={data?.fatherName} changeValue={(e)=>setSellerInfo({...sellerInfo,fatherName:e.target.value})} />
        <Input  name={'Date of birth'}  req={true} type={'Date'} defaultValue={isValidDate(data?.dateOfBirth) ? new Date(data?.dateOfBirth).toISOString().split('T')[0] : ''} changeValue={(e)=>setSellerInfo({...sellerInfo,dateOfBirth:e.target.value})} />
        <Select  name={'Business Type'} value={sellerInfo?.type||data?.type} setValue={setValue} req={true} data={['Physical','Juridic']} />
        <Input  name={'ID/Passport'}  req={true} defaultValue={data?.documentNumber} changeValue={(e)=>setSellerInfo({...sellerInfo,documentNumber:e.target.value})}/>
        <Input  name={'SSN'}  req={true} defaultValue={data?.ssn} changeValue={(e)=>setSellerInfo({...sellerInfo,ssn:e.target.value})} />
        <Input  name={'TIN'}  req={true} defaultValue={data?.taxpayerIdentificationNumber} changeValue={(e)=>setSellerInfo({...sellerInfo,taxpayerIdentificationNumber:e.target.value})} />
    </div>
  )
}

export default Seller