// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.card {
  background: #FFFFFF;
  max-width: 467px;
  width: 100%;
  box-shadow: 0px 2px 8px 0px rgba(171, 164, 164, 0.2509803922);
  border: none;
  border-radius: 10px;
  cursor: pointer;
}
.card > img {
  width: 100%;
  max-height: 416px;
}
.card > div {
  display: flex;
  align-items: center;
  justify-content: center;
  padding-top: 31px;
  padding-bottom: 31px;
}
.card > div > span {
  font-family: Montserrat;
  font-size: 32px;
  font-weight: 700;
  line-height: 39.01px;
  text-align: center;
  color: #0B7E9B;
}`, "",{"version":3,"sources":["webpack://./src/Pages/HomePage/Components/Cards/Card.scss"],"names":[],"mappings":"AAAA;EACI,mBAAA;EACA,gBAAA;EACA,WAAA;EACA,6DAAA;EACA,YAAA;EACA,mBAAA;EACA,eAAA;AACJ;AAAI;EACI,WAAA;EACA,iBAAA;AAER;AAAI;EACI,aAAA;EACA,mBAAA;EACA,uBAAA;EACA,iBAAA;EACA,oBAAA;AAER;AADQ;EACI,uBAAA;EACA,eAAA;EACA,gBAAA;EACA,oBAAA;EACA,kBAAA;EACA,cAAA;AAGZ","sourcesContent":[".card{\n    background: #FFFFFF;\n    max-width: 467px;\n    width: 100%;\n    box-shadow: 0px 2px 8px 0px #ABA4A440;\n    border: none;\n    border-radius: 10px;\n    cursor: pointer;\n    >img{\n        width: 100%;\n        max-height: 416px;\n    }\n    >div{\n        display: flex;\n        align-items: center;\n        justify-content: center;\n        padding-top: 31px;\n        padding-bottom: 31px;\n        >span{\n            font-family: Montserrat;\n            font-size: 32px;\n            font-weight: 700;\n            line-height: 39.01px;\n            text-align: center;\n            color: #0B7E9B;\n\n        }\n    }\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
