import axios from 'axios';

const url = process.env.REACT_APP_API_URI;
const inst = axios.create({
  baseURL: `${process.env.REACT_APP_API_URI}/api`,
  timeout: 120000,
});

inst.interceptors.response.use(
  (response) => response.data,
  async (error) => {
    const originalRequest = error.config;
    if (error?.response?.status === 401 && !originalRequest._retry) {
      originalRequest._retry = true; 
      try {
        const auth = localStorage.getItem('auth')?JSON.parse(localStorage.getItem('auth')):null
        const refreshToken = auth?.refreshToken;
        const accessToken = auth?.accessToken;

        const refreshResponse = await axios.post(
          `https://identity.${url}/Identity/v1/Refresh`,
          { refreshToken },
          { headers: { Authorization: `Bearer ${accessToken}` } }
        );

        localStorage.setItem('auth', JSON.stringify(refreshResponse?.data));

        originalRequest.headers['Authorization'] = `Bearer ${refreshResponse?.data?.accessToken}`;

        return axios(originalRequest);
      } catch (refreshError) {
        console.error(refreshError)
        localStorage.removeItem('auth');
        window.location.href = '/login';
      }
    }
    return Promise.reject(error);
  }
);

inst.interceptors.request.use(
  (req) => {
   const accessToken = localStorage.getItem('auth')?JSON.parse(localStorage.getItem('auth')).accessToken:''
    if (accessToken) {
      req.headers['Authorization'] = `Bearer ${accessToken}`;
    }
    return req;
  },
  (error) => Promise.reject(error)
);

export default inst;
