import React, { useState } from 'react'
import './Steps.scss'
import {ReactComponent as UploadIcon} from './../../../../assets/Frame 2529.svg'
function Photos({images,setImages}) {
 const [uploadIcons,setUploadIcons] = useState([{
    id:Math.random(),
    value:''
},
{
    id:Math.random(),
    value:''
},
{
    id:Math.random(),
    value:''
},
{
    id:Math.random(),
    value:''
},
{
    id:Math.random(),
    value:''
},
{
    id:Math.random(),
    value:''
},
{
    id:Math.random(),
    value:''
},
{
    id:Math.random(),
    value:''
}
]) 
 const handleImageUpload = (e)=>{
    setImages([...images,...e.target.files])
 }

  return (
    <div className='phtos-container'>
        <div className='phtos-info'>
            <p>Photos</p>
            <div>
            <span>Use up to ten photos to show your item`s most important qualities</span>
            <div>
                <span>Tips:</span>
                <span>Use natural light and no flash.</span>
                <span>Include a common abject for scale</span>
                <span>Show the item being held, worn, or used</span>
                <span>Add photos to your variations so buyers can see all their options.</span>
            </div>
            </div>
        </div>
        <div className='phtots-container'>
            <span>Upload up to 8 photos</span>
            <div>
              {images.map((i,key)=><img key={key} src={URL.createObjectURL(i)} alt='phtot'/>)}
              {uploadIcons?.slice(0,8-images?.length)?.map((i,key)=><label htmlFor='upload-photo' key={key}>
                    <UploadIcon/>
                    <input type='file' id='upload-photo' style={{display:'none'}} accept="image/*" 
                           multiple onChange={(e)=>handleImageUpload(e)} 
                />
                </label>)}
            </div>
            <span className='info'>Add Photos 5MB maximum size</span>
        </div>
    </div>
  )
}

export default Photos